<script>
</script>

<footer class="footer">
    <div class="container cmr">
        <span class="date">©{new Date().getFullYear()}</span>
        <div class="logo-container">
            <a href="/" class="logo">
                <img src="icons/signature1.svg" alt="asif" />
            </a>
        </div>
        <div class="social">
            <a href="https://www.instagram.com/asifmohd95/" target="blank"
                ><i data-feather="instagram" class="icon" /></a
            >
            <a href="https://twitter.com/AsifMohd95" target="blank"
                ><i data-feather="twitter" class="icon" /></a
            >
            <a
                href="https://www.linkedin.com/in/asif-mohammed-18b2bb150/"
                target="blank"><i data-feather="linkedin" class="icon" /></a
            >
            <a href="https://github.com/Asifm95/" target="blank"
                ><i data-feather="github" class="icon" /></a
            >
        </div>
    </div>
</footer>

<style lang="scss">.footer {
  width: 100%;
  height: 140px;
  position: relative;
  background-color: black;
}
.footer .container {
  top: 0;
  height: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 60% 1fr;
}
@media (max-width: 400px) {
  .footer .container {
    flex-direction: column;
  }
}
.footer .container .date {
  color: #8f9ba8;
  font-size: 12px;
}
.footer .container .logo-container {
  text-align: center;
}
.footer .container .logo-container img {
  width: 60px;
  height: 55px;
}
.footer .container .social {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 400px) {
  .footer .container .social {
    flex-wrap: wrap;
  }
}
.footer .container .social a {
  color: #8f9ba8;
}
.footer .container .social a .icon {
  width: 16px;
  height: 16px;
}
@media (max-width: 600px) {
  .footer .container .social a .icon {
    width: 13px;
    height: 13px;
  }
}</style>
