<script>
    import Typed from 'typed.js';
    import { onMount } from 'svelte';

    const options = {
        strings: [
            'lifelong learner.',
            'TS enthusiast.',
            'loves building cool stuff.',
        ],
        backDelay: 1800,
        backSpeed: 20,
        typeSpeed: 40,
        startDelay: 1300,
    };
    let el;
    let typedObj = null;
    onMount(() => {
        typedObj = new Typed(el, options);
    });
</script>

<style lang="scss">.hero {
  position: relative;
  width: 100%;
  height: 100vh;
}
.hero .container {
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: initial;
  height: 100%;
  position: relative;
}
.hero .container .hero-content {
  display: flex;
  flex-direction: row;
  justify-content: initial;
  align-items: flex-end;
  backface-visibility: hidden;
  opacity: 1;
}
@media (min-width: 600px) {
  .hero .container .hero-content {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 140px;
    padding-bottom: 60px;
  }
}
.hero .container .hero-content .title {
  color: #fff;
  letter-spacing: -1px;
  line-height: 1.8;
  font-size: clamp(22px, 5vw, 30px);
  max-width: 90%;
  width: 650px;
}
@media (max-width: 600px) {
  .hero .container .hero-content .title {
    height: 300px;
    max-width: 95%;
    min-height: 200px;
    position: absolute;
    bottom: 10px;
    padding-bottom: 60px;
  }
}
.hero .container .hero-content .title .typed {
  color: #8f9ba8;
}
@media (max-width: 350px) {
  .hero .container .hero-content .title .typed::before {
    content: "";
    display: block;
  }
}
.hero .container .hero-down {
  position: absolute;
  bottom: 0;
  padding-bottom: 60px;
}</style>

<div class="hero">
    <div class="container cmr">
        <div class="hero-content">
            <h1 class="title">
                UI / UX developer, front-end engineer,
                <span class="typed" bind:this={el} />
            </h1>
        </div>
        <span class="hero-down"><i data-feather="chevron-down" /></span>
    </div>
</div>
