<script>
    import { links } from './model/links';
    import { onMount } from 'svelte';
    import { heroAnimation } from './animations.js';

    let ref;
    let scrolled;
    let show = false;
    onMount(() => {
        heroAnimation();
    });
    const handleScroll = (e) => {
        var s = window.scrollY;
        scrolled = s > 0;
    };
</script>

<style lang="scss">.nav {
  position: fixed;
  width: 100%;
  height: 140px;
  z-index: 4;
  transition: all 0.4s ease;
}
@media (max-width: 600px) {
  .nav {
    width: 100%;
    height: 90px;
  }
}
.nav.scrolled {
  background: black;
  height: 90px;
}
.nav .container {
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.nav .container .logo {
  width: 60px;
  height: 55px;
}
@media (max-width: 600px) {
  .nav .container .logo {
    width: 50px;
    height: 50px;
  }
}
.nav .container .logo img {
  width: 100%;
  height: 100%;
}
.nav .container .nav-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.nav .container .nav-right .menu-icon {
  display: none;
  width: 19px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .nav .container .nav-right .menu-icon {
    display: inherit;
  }
}
@media (max-width: 600px) {
  .nav .container .nav-right .nav-links {
    display: none;
  }
}
.nav .nav-links-mobile {
  display: none;
  transition: all 0.4s ease;
}
@media (max-width: 600px) {
  .nav .nav-links-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #000;
    position: fixed !important;
    padding: 0px 20px;
    height: 60px;
    width: 100% !important;
    z-index: -1;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
.nav .nav-links-mobile.show {
  top: 90px;
  opacity: 1;
}
.nav .nav-link {
  padding: 0 24px;
  text-transform: capitalize;
  color: white;
  opacity: 1;
}
@media (max-width: 600px) {
  .nav .nav-link {
    padding: 0;
  }
}
.nav .nav-link:hover {
  opacity: 0.6;
}</style>

<svelte:window on:scroll={handleScroll} />

<nav class="nav" class:scrolled bind:this={ref}>
    <div class="container cmr">
        <a href="/" class="logo">
            <img src="icons/signature1.svg" alt="asif" />
        </a>
        <div class="nav-right">
            <div class="nav-links">
                {#each links as link}
                    <a href={link.ref} class="nav-link">{link.label}</a>
                {/each}
            </div>
            <span class="menu-icon" on:click={() => (show = !show)}><i
                    data-feather="menu" /></span>
        </div>
    </div>
    <div class:show class="nav-links-mobile">
        {#each links as link}
            <a
                href={link.ref}
                class="nav-link"
                on:click={() => (show = false)}>{link.label}</a>
        {/each}
    </div>
</nav>
